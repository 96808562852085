import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {FirestoreProvider} from "react-firestore";
import * as firebase from "firebase";
import FirestoreConnect from "./FirestoreConnect";
import {BrowserRouter, Route,} from "react-router-dom";

const config = {
    apiKey: 'AIzaSyD6f5lu4k_Wrv0hk51o4InJFgeRkrHO2gc',
    projectId: "wybierator-produktow",
};

firebase.initializeApp(config);
const firestore = firebase.firestore();
const settings = {/* your settings... */ timestampsInSnapshots: true};
firestore.settings(settings);


ReactDOM.render(
    <BrowserRouter>
        <FirestoreProvider firebase={firebase}>
            <Route exact path='/:id' component={FirestoreConnect}/>
        </FirestoreProvider>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
