import React from 'react';

const LoadingScreen = () => {
    return (
        <div style={{
            position: "absolute",
            left: "50%",
            marginLeft: "-50px",
            top: "50%",
            marginTop: "-50px",
            width: "100px",
            height: "100px"
        }}>
            Ładuję...
        </div>
    );
};

export default LoadingScreen;