import React, {Component} from 'react';
import Title from "./components/Title";
import * as cxs from "cxs";
import Image from "./components/Image";
import Description from "./components/Description";
import Button from "./components/Button";

class App extends Component {
    render() {
        const {iframeData} = this.props;
        return (
            <div className="App">
                <Title text={iframeData.title}/>
                <ul className={ulStyle}>
                    <li className={liStyle}>
                        <Image src={iframeData.imageLink}/>
                    </li>
                    <li className={liStyle}>
                        <Description text={iframeData.description}/>
                        <Button link={iframeData.buttonLink}/>
                    </li>
                </ul>
            </div>
        );
    }
}

const ulStyle = cxs({
        "display": "grid",
        "gridTemplateColumns": "repeat(auto-fit, minmax(220px, 1fr))",
        "gridGap": "10px",
        "listStyleType": "none"
    }
);

const liStyle = cxs({
        "backgroundColor": "#fff",
        "borderRadius": "3px",
        "padding": "20px",
        "fontSize": "14px",
        "alignSelf": "center"
    }
);

export default App;
