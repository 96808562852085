import React from "react";
import * as cxs from "cxs";

const Image = (props) => {

    return (
        <img className={titleImage} alt={"Wybierator pomoże w wyborze."}
             src={props.src}/>
    )
};

const titleImage = cxs({
    width: "100%"
});

export default Image;