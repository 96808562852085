import React from "react";
import * as cxs from "cxs";

const Title = (props) => {

    return (
        <h1 className={title}>
            {props.text}
        </h1>
    )
};

const title = cxs({
    // fontFamily: "Trebuchet MS",
    fontWeight: "bold",
    fontSize: "20px",
    textAlign: "center",
    margin: "10px"
});

export default Title;