import React from "react";
import * as cxs from "cxs";

const Description = (props) => {

    return (
        <h1 className={description}>
            {props.text}
        </h1>
    )
};

const description = cxs({
    "fontFamily": "Trebuchet MS",
    "fontWeight": "200",
    "fontSize": "16px",
    "textAlign": "center",
    "margin": "10px"
});

export default Description;