import App from "./App";
import {FirestoreDocument} from "react-firestore";
import React from "react";
import LoadingScreen from "./components/LoadingScreen";
import {withRouter} from "react-router-dom";

function FirestoreConnect(props) {
    const {id} = props.match.params;
    console.log(props.match.params);
    return (
        <FirestoreDocument
            path={"iframe/" + id}
            render={({isLoading, data}) => {
                return isLoading ? (
                    <LoadingScreen/>
                ) : (
                    <App iframeData={data}/>
                );
            }}
        />
    );
}

export default withRouter(FirestoreConnect);