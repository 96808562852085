import React from "react";
import * as cxs from "cxs";

const Button = (props) => {

    return (
        <a id="link" href={props.link} style={{textDecoration: "none"}} target="_blank">
            <div className={button}>
                URUCHOM
            </div>
        </a>
    )
};

const button = cxs(
    {
        "backgroundImage": "linear-gradient(to right, #1A2980 0%, #26D0CE 51%, #1A2980 80%)",
        "borderRadius": "15px",
        "padding": "20px",
        "color": "white",
        "transition": "0.3s",
        "backgroundSize": "200% auto",
        "textAlign": "center",
        "width": "60%",
        "margin": "auto",
        "fontFamily": "Lucida Sans Unicode",
        "fontSize": "18px",
        "boxShadow": "0px 8px 15px rgba(0, 0, 0, 0.3)",
        "fontWeight": "bold",
        ':hover': {
            "backgroundPosition": "right center",
            "boxShadow": "0px 15px 20px rgba(0, 0, 0, 0.2)"
        }
    }
);

export default Button;